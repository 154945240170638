import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, devices, fontWeights } from '../styles/theme';
import { GiHamburgerMenu } from '@react-icons/all-files/gi/GiHamburgerMenu';
import { ButtonLink, TextNavLink } from '../styles/shared';
import LogoSVG from '../assets/neu-logo-horz-alt-black.svg';
import { homePageContent } from '../utils/dataConfig';
import Modal from './Modal';
import { Link } from 'gatsby';
import { getCountdown } from '../utils/helpers';
const endDate = new Date('May 31, 2021 23:59:59').getTime();
const Header = () => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleClick = () => setOpen(prev => !prev);

  const countDownEnded = endDate - Date.now() <= 0;
  const [countDown, setCountdown] = useState({});
  useEffect(() => {
    let intervalRef = null;
    if (!countDownEnded) {
      setCountdown(getCountdown(endDate));
      intervalRef = setInterval(
        () => setCountdown(getCountdown(endDate)),
        1000
      );
    }
    return () => intervalRef && clearInterval(intervalRef);
  }, [countDownEnded]);
  return (
    <>
      <NavContainer>
        <LogoLink to='/'>
          <SiteLogo src={LogoSVG} />
        </LogoLink>
        <MainNav>
          <TextNavLink
            to='/communities/'
            activeClassName='active'
            className='desktop'
          >
            Communities
          </TextNavLink>
          <TextNavLink
            to='/own-vs-rent/'
            activeClassName='active'
            className='desktop'
          >
            Own or Rent
          </TextNavLink>
          <TextNavLink
            to='/floor-plans/'
            activeClassName='active'
            className='desktop'
          >
            Floor Plans
          </TextNavLink>
          <TextNavLink
            to='/gallery/'
            activeClassName='active'
            className='desktop'
          >
            Gallery
          </TextNavLink>
        </MainNav>
        <SubNav>
          <ButtonLink to='/builder/' className='desktop'>
            <p>
              <span>Build Your Home</span>
            </p>
          </ButtonLink>
          <HamburgerButton
            className='mobile'
            ref={anchorEl}
            onClick={handleClick}
          >
            <GiHamburgerMenu size='1.5rem' />
          </HamburgerButton>
        </SubNav>
      </NavContainer>
      {open && (
        <Modal
          anchorStyle={{
            right: '5%',
            top: 'var(--nav-bar-height)',
            width: '60%',
          }}
          handleClose={() => setOpen(false)}
        >
          <DropdownNav>
            <TextNavLink to='/' activeClassName='active'>
              Home
            </TextNavLink>
            <TextNavLink to='/communities/' activeClassName='active'>
              Communities
            </TextNavLink>
            <TextNavLink to='/own-vs-rent/' activeClassName='active'>
              Own or Rent
            </TextNavLink>
            <TextNavLink to='/floor-plans/' activeClassName='active'>
              Floor Plans
            </TextNavLink>
            <TextNavLink to='/gallery/' activeClassName='active'>
              Gallery
            </TextNavLink>
            <TextNavLink to='/builder/' activeClassName='active'>
              Build Your Home
            </TextNavLink>
          </DropdownNav>
        </Modal>
      )}
    </>
  );
};

const NavContainer = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--nav-bar-height);
  padding: var(--nav-bar-padding-y) var(--nav-bar-padding-x);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white}D9;
  backdrop-filter: blur(1rem);
  z-index: 5;

  .desktop {
    @media (${devices.xs}) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @media (${devices.xs}) {
      display: flex;
    }
  }
`;
const SiteLogo = styled.img`
  height: 2.5rem;
`;
const LogoLink = styled(Link)`
  padding-right: 1.5rem;
  @media (${devices.s}) {
    padding-right: 1rem;
  }
`;
const MainNav = styled.nav`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.375rem;
  grid-template-columns: repeat(2, 1fr);
  justify-items: stretch;
  @media (${devices.m}) {
    column-gap: 1rem;
    margin-right: 0.5rem;
  }
  @media (${devices.s}) {
    column-gap: 0.5rem;
    > a {
      font-size: clamp(0.8rem, 1.8vw, 1rem);
    }
  }
`;
const SubNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3rem;
`;
const HamburgerButton = styled.button`
  align-items: center;
`;
const Banner = styled.section`
  width: 100%;
  //height: var(--banner-height);
  padding: 0.5rem 0.5rem;
  font-size: 1.125rem;
  line-height: 2.25rem;
  font-weight: ${fontWeights.regular};
  .timer-divs {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    // font-size: 1.125rem;
    // font-family: 'Azo Sans';

    padding: 0 0 0.125rem 0;
  }
  .timer-divs .countdown {
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    padding: 0 0 0.125rem 0.5rem;
  }
  .timer-divs .countdown span {
    display: block;
    font-size: 0.625rem;
    font-weight: 400;
  }
  color: ${colors.white};
  background-color: ${colors.blue};
  display: grid;
  justify-content: center;
  justify-items: center;
  @media (${devices.s}) {
    font-size: 1.125rem;
    line-height: 2.4rem;
    //height: 3rem;
  }
  @media (${devices.xs}) {
    line-height: 2rem;
    //min-height: 3rem;
    //height: auto;
    text-align: center;
    //padding: 0.8rem;
    //display: block;
    width: 100%;
    //> *:not(:last-child) {
    //  margin-right: 1ch;
    //}
  }
`;
const DropdownNav = styled.ul`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  box-shadow: 0 9px 16px 0 rgba(0, 0, 0, 0.47);
  padding: 1rem;
  row-gap: 1rem;
  font-size: 1.2rem;
  > * {
    padding: 1rem;
  }
  background-color: white;
`;
export default Header;
