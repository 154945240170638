import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonLink, TextStyleButton } from '../styles/shared';
import { colors, devices, fontWeights } from '../styles/theme';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Modal from './Modal';
import Partner from './modals/Partner';
import LogoSymbol from '../assets/neu-logo-symbol-black.svg';
import { IoLogoTwitter } from '@react-icons/all-files/io/IoLogoTwitter';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { IoLogoInstagram } from '@react-icons/all-files/io5/IoLogoInstagram';
import { GatsbyImage } from 'gatsby-plugin-image';

const externalLinkAttr = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

const Footer = () => {
  const data = useStaticQuery(graphql`
    query getFooterImages {
      footer: allFile(filter: { name: { regex: "/^footer/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, width: 80, placeholder: NONE)
          }
        }
      }
      decor: allFile(filter: { name: { regex: "/^home-decor/" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 70, width: 800, placeholder: NONE)
          }
        }
      }
    }
  `);
  const decorImages = data.decor.nodes
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(image => image.childImageSharp.gatsbyImageData);
  const houseImage = data.footer.nodes.find(
    image => image.name === 'footer-house'
  );
  const [modal, setModal] = useState(null);
  const handleClose = () => setModal(null);
  return (
    <Container>
      <Reserve>
        <h2>Reserve your place in the community now</h2>
        <ButtonLink to={'/builder'}>
          <p>
            <span>Start Building</span>
          </p>
        </ButtonLink>
      </Reserve>
      <Right>
        <SiteMap>
          <Link className='left' to='/communities/'>
            Communities
          </Link>
          <Link className='left' to='/own-vs-rent/'>
            Own or Rent
          </Link>
          <Link className='left' to='/floor-plans/'>
            Floor Plans
          </Link>
          <Link className='left' to='/gallery/'>
            Gallery
          </Link>
          <Link to='/builder/'>Start Building</Link>
          <TextStyleButton onClick={() => setModal('partner')}>
            Partner
          </TextStyleButton>
          <a href='mailto:info@neucommunity.com'>Contact Us</a>
          <Link to='/privacy/'>Privacy Policy</Link>
          <Link to='/terms/'>Terms</Link>
        </SiteMap>
        <Contact>
          <img className='logo' src={LogoSymbol} alt='site logo' />
          <p>(877) 363-8466</p>
          <a href='mailto:info@neucommunity.com'>info@neucommunity.com</a>
          <Social>
            <a href='https://twitter.com/NEUcommunities' {...externalLinkAttr}>
              <IoLogoTwitter />
            </a>
            <a
              href='https://www.facebook.com/Neu-Community-109231444637577'
              {...externalLinkAttr}
            >
              <FaFacebookF />
            </a>
            <a
              href='https://www.instagram.com/neucommunity/'
              {...externalLinkAttr}
            >
              <IoLogoInstagram />
            </a>
          </Social>
        </Contact>
      </Right>
      <Terms>
        <EqualHousing>
          <HouseImage
            image={houseImage.childImageSharp.gatsbyImageData}
            alt='house icon'
            objectFit='contain'
          />
          <p>Neu Community is an equal housing opportunity developer.</p>
          <p>&copy; 2021 Neu Community, Inc. All Rights Reserved.</p>
        </EqualHousing>
      </Terms>
      {modal === 'partner' && (
        <Modal
          anchorStyle={{ width: '100%', height: '100%' }}
          handleClose={handleClose}
        >
          <Partner handleClose={handleClose} />
        </Modal>
      )}
      <DecorLeft
        image={decorImages[4]}
        style={{ position: 'absolute' }}
        alt='background decor art'
      />
      <DecorRight
        image={decorImages[5]}
        style={{ position: 'absolute' }}
        alt='background decor art'
      />
    </Container>
  );
};

const Container = styled.footer`
  padding: var(--footer-padding-top) 0 var(--footer-padding-bottom);
  margin: 0 var(--body-padding-x);
  border-top: 1px solid ${colors.black};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--body-column-gap);
  row-gap: 3.75rem;
  position: relative;
  @media (${devices.s}) {
    grid-template-columns: auto auto;
    justify-content: space-between;
    column-gap: 0;
    row-gap: 6rem;
  }
  @media (${devices.xs}) {
    row-gap: 2.8rem;
  }
`;
const DecorLeft = styled(GatsbyImage)`
  width: 40%;
  left: calc(var(--body-padding-x) * -1);
  bottom: 0;
  z-index: -1;
  @media (${devices.s}) {
    opacity: 0.6;
  }
`;
const DecorRight = styled(GatsbyImage)`
  width: 25%;
  right: calc(var(--body-padding-x) * -1);
  bottom: 0;
  z-index: -1;
  @media (${devices.s}) {
    opacity: 0.6;
  }
`;
const Reserve = styled.div`
  display: grid;
  row-gap: 2.5rem; // fail safe
  align-content: space-between;

  h2 {
    font-weight: ${fontWeights.bold};
    font-size: 2.125rem;
    line-height: 3.5rem;
  }

  @media (${devices.s}) {
    h2 {
      font-weight: ${fontWeights.regular};
      font-size: 2.4rem;
      line-height: 4rem;
    }

    a {
      //width: 100%;
      //width: 13.7rem;
      height: 4rem;
    }
  }

  @media (${devices.xs}) {
    grid-column: span 2;
    row-gap: 1rem;
    h2 {
      font-size: 1.72rem;
      line-height: 2.9rem;
    }

    a {
      border-color: transparent;
      background-color: ${colors.blue};
      color: ${colors.white};
      font-weight: ${fontWeights.bold};
    }
  }
`;
const Right = styled.div`
  grid-row: 1 / span 2;
  grid-column: 2 / span 1;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  @media (${devices.s}) {
    grid-column: span 1 / -1;
    grid-row: 1 / span 2;
    grid-template-columns: auto;
  }
  @media (${devices.xs}) {
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-column: span 2;
    grid-row: auto;
  }
`;
const SiteMap = styled.div`
  display: grid;
  align-content: space-between;
  grid-template-columns: auto auto;
  grid-auto-flow: dense;
  column-gap: 2rem;
  margin-top: 1.5rem;

  .left {
    grid-column: 1 / span 1;
  }

  a,
  button {
    font-size: 1rem;
    line-height: 1.5rem;
    width: max-content;

    &:hover {
      color: ${colors.blue};
      text-decoration: underline;
    }
  }

  @media (${devices.s}) {
    margin-left: 5rem;
  }
  @media (${devices.xs}) {
    margin-top: 0;
    margin-left: 0;
    grid-row: span 2;
    column-gap: 1rem;
    a,
    button {
      font-size: clamp(0.75rem, 3.75vw, 1rem);
    }
  }
`;
const Contact = styled.article`
  display: grid;
  align-content: space-between;

  p,
  a {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .logo {
    height: 5rem;
  }

  @media (${devices.s}) {
    align-content: end;
    grid-template-columns: auto 1fr;
    column-gap: 8vw;
    row-gap: 0.75rem;
    .logo {
      grid-row: span 3;
      align-self: end;
    }
  }
  @media (${devices.xs}) {
    grid-row: span 2;
    grid-column: span 1 / -1;
    grid-template-columns: auto;
    p {
      padding-top: 0.5rem;
    }

    a {
      font-size: clamp(0.75rem, 3vw, 1rem);
    }
  }
`;

const Social = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.5rem;
  justify-content: space-between;

  & * {
    width: 1.375rem;
    height: 1.375rem;
  }

  @media (${devices.s}) {
    column-gap: 3vw;
  }
`;
const Terms = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  column-gap: 1rem;

  font-size: 0.8rem;
  line-height: 1.125rem;
  > a {
    &:hover {
      color: ${colors.blue};
      text-decoration: underline;
    }
  }

  @media (${devices.xs}) {
    grid-column: 1 / -1;
    justify-self: center;
  }
`;
const EqualHousing = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 0.5rem;
  column-gap: 1rem;
`;
const HouseImage = styled(GatsbyImage)`
  width: 2.5rem;
  grid-row: span 2;
`;
export default Footer;
